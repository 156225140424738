<template>
	<el-scrollbar class="uu-box">
		<div class="page-breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.orderManage') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'refundList' }">{{ $t('seller.breadcrumb.refundList') }}
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.refundInfo') }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="page-head" v-if="refundInfo">
			<div class="head-status">
				<div class="status-box">
					<template v-if="refundInfo.refund_status == 1">
						<!-- 退款处理 -->
						<template v-if="refundInfo.goods_status !== 3">
							<h3>{{$t('seller.refund.pending')}}</h3>
						</template>
						<template v-else>
							<h3>{{refundInfo.consult_newest.step | statusFilter($t('seller.filters.refundStatus3'))}}</h3>
						</template>
					</template>
					<template v-else>
						<h3>{{ refundInfo.refund_status===5?10:refundInfo.refund_status | statusFilter($t('seller.filters.refundStatus1')) }}</h3>
					</template>
				</div>
				<div class="status-bar">
					<el-steps :active="orderStatus" finish-status="success" simple>
						<el-step :title="refundInfo.goods_status !== 3 ? $t('seller.refund.step1-1') : $t('seller.refund.step1-2')"></el-step>
						<el-step :title="refundInfo.goods_status !== 3 ? $t('seller.refund.step2-1') : $t('seller.refund.step2-2')"></el-step>
						<el-step v-if="refundInfo.goods_status === 3" :title="$t('seller.refund.step3')"></el-step>
						<el-step :title="refundInfo.goods_status !== 3 ? $t('seller.refund.step4-1') : $t('seller.refund.step4-2')"></el-step>
					</el-steps>
				</div>
				<div></div>
			</div>
			<template v-if="refundInfo.refund_status == 1">
				<template v-if="refundInfo.goods_status !== 3">
					<ul class="head-process">
						<li>{{$t('seller.refund.text1')}}</li>
					</ul>
					<ul class="head-tips">
						<li v-for="(item,index) in $t('seller.refund.tips1')" :key="index">{{item}}</li>
					</ul>
					<ul class="head-actions">
						<li>
							<el-button type="primary" size="medium" round @click="handleAgree">{{ $t('seller.actions.agreeRefund') }}</el-button>
							<el-button size="medium" round @click="dialogVisibleRefuse = true">{{ $t('seller.actions.refuseApply') }}</el-button>
						</li>
					</ul>
				</template>
				<template v-else>
					<ul class="head-process">
						<template v-if="refundInfo.consult_newest.step == 1">
							<li>{{$t('seller.refund.text2')}}</li>
						</template>
						<template v-else-if="refundInfo.consult_newest.step == 2">
							<li>{{$t('seller.refund.tips2-1')}}</li>
							<li>{{$t('seller.refund.tips2-2')}}{{ refundInfo.actual_price | priceFilter() }}{{$t('seller.refund.tips2-3')}}</li>
							<li v-for="(item,index) in $t('seller.refund.tips2-4')" :key="index">{{item}}</li>
						</template>
						<template v-else-if="refundInfo.consult_newest.step == 3">
							<li>{{$t('seller.refund.tips2-5')}}</li>
						</template>
					</ul>
					<ul class="head-tips" v-if="refundInfo.consult_newest.step !== 2">
						<template v-if="refundInfo.consult_newest.step == 1">
							<li v-for="(item,index) in $t('seller.refund.tips2-6')" :key="index">{{item}}</li>
						</template>
						<template v-else-if="refundInfo.consult_newest.step == 3">
							<li v-for="(item,index) in $t('seller.refund.tips2-7')" :key="index">{{item}}</li>
							<li>{{$t('seller.refund.tips2-8')}}{{ refundInfo.actual_price | priceFilter() }}{{$t('seller.refund.tips2-9')}}</li>
						</template>
					</ul>
					<ul class="head-actions">
						<li v-if="refundInfo.consult_newest.step == 1">
							<el-button type="primary" size="medium" round @click="dialogVisibleReturn = true">{{ $t('seller.actions.agreeRefunds') }}</el-button>
							<el-button size="medium" round @click="dialogVisibleRefuse = true">{{ $t('seller.actions.refuseRefunds') }}</el-button>
						</li>
						<li v-if="refundInfo.consult_newest.step == 2">
							<el-button type="primary" size="medium" round @click="handleAgree">{{ $t('seller.actions.refuseRefunds2') }}</el-button>
						</li>
						<li v-if="refundInfo.consult_newest.step == 3">
							<el-button type="primary" size="medium" round @click="handleAgree">{{ $t('seller.actions.refuseRefunds3') }}</el-button>
							<el-button size="medium" round @click="dialogVisibleRefuse = true">{{ $t('seller.actions.refuseRefund') }}</el-button>
						</li>
					</ul>
				</template>
			</template>
			<template v-else>
				<ul class="head-process" v-if="refundInfo.refund_status == 2">
					<li>{{$t('seller.refund.statusText1')}}：{{ refundInfo.update_time | parseTime() }}</li>
					<li>{{$t('seller.refund.statusText2')}}：￥{{ refundInfo.actual_price | priceFilter() }}{{$t('seller.unit.yuan')}}</li>
				</ul>
				<ul class="head-process" v-if="refundInfo.refund_status == 5">
					<li>{{$t('seller.refund.statusText3')}}：{{ refundInfo.update_time | parseTime() }}</li>
					<li>{{$t('seller.refund.statusTips3')}}</li>
				</ul>
			</template>
		</div>
		<div class="page-main" v-if="refundInfo">
			<div class="detail-top">
				<div class="title">{{ $t('seller.refund.title') }}</div>
			</div>
			<div class="detail-layout">
				<div class="item">
					<div class="item-title">{{ $t('seller.refund.subtitle1') }}</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.buyerName') }}：</span>
						<span class="item-value">{{ memberInfo.real_name }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.orderSn') }}：</span>
						<span class="item-value">{{ refundInfo.order_sn }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.payTime') }}：</span>
						<span class="item-value">{{ refundInfo.closing_time | parseTime() }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.postage') }}：</span>
						<span class="item-value">￥{{ orderInfo.delivery_free }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.orderTotal') }}：</span>
						<span class="item-value">￥{{ orderInfo.order_amount | priceFilter() }}</span>
					</div>
				</div>
				<div class="item">
					<div class="item-title">{{ $t('seller.refund.subtitle2') }}</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.number') }}：</span>
						<span class="item-value">{{ refundInfo.trade_no }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.price') }}：</span>
						<span class="item-value">￥{{ refundInfo.actual_price | priceFilter() }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.reason') }}：</span>
						<span class="item-value">{{ refundInfo.reason }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.require') }}：</span>
						<span class="item-value">{{ refundInfo.goods_status | statusFilter($t('seller.filters.goodsStatus')) }}</span>
					</div>
					<div class="item-row">
						<span class="item-label">{{ $t('seller.refund.explain') }}：</span>
						<span class="item-value">{{ refundInfo.description || '缺' }}</span>
					</div>
				</div>
				<div class="item">
					<div class="item-title">{{ $t('seller.refund.subtitle3') }}</div>
					<div class="item-goods" v-for="item, index in orderInfo.order_goods" :key="index">
						<div class="item-pic"><img :src="item.goods_image" @error="imageLoadError" alt=""></div>
						<div class="item-main">
							<div class="name">{{ item.goods_name }}</div>
							<div class="text">{{ item.specification }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="history-title">{{$t('seller.refund.history')}}</div>
        <div class="history-list">
            <div class="item" v-for="(item,index) in consultList" :key="index">
                <div class="pic">
					<img v-if="[1,3].includes(item.step)" :src="item.portrait" @error="userLoadError" alt="">
					<img v-else :src="item.portrait" @error="systemLoadError" alt="">
				</div>
                <div class="item-main">
                    <div class="name">{{ item.user_name }}</div>
                    <div class="time">{{ item.create_time | parseTime() }}</div>
                    <div class="text">{{ item.content }}</div>
                </div>
            </div>
        </div>
		
		<el-dialog :title="$t('seller.dialogTitle.refundPending')" :visible.sync="dialogVisibleReturn" width="800px" :before-close="handleCloseReturn">
			<el-form :model="returnForm" :rules="returnRules" ref="returnForm" label-width="120px" style="padding-right:10%">
				<el-form-item :label="$t('seller.formItem.refundsAddress')" prop="receiving_address_id">
					<el-select v-model="returnForm.receiving_address_id" :placeholder="$t('seller.placeholder.refundsAddress')" style="width: 100%">
						<el-option v-for="(item,index) in addressList" :key="index" :label="getAddressStr(item)" :value="item.shipaddr_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.refundsExplain')">
					<el-input v-model="returnForm.description" type="textarea" :rows="4" :placeholder="$t('seller.placeholder.refundsExplain')"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleAgreeReturn">{{$t('seller.actions.agreeRefunds')}}</el-button>
					<el-button @click="handleCloseReturn">{{$t('seller.actions.cancel&Close')}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 拒绝退款 -->
		<el-dialog :title="$t('seller.dialogTitle.refuseRefund')" :visible.sync="dialogVisibleRefuse" width="800px" :before-close="handleCloseRefuse">
			<el-form :model="refuseForm" :rules="refuseRules" ref="refuseForm" label-width="120px" style="padding-right:10%">
				<el-form-item :label="$t('seller.formItem.refuseReason')" prop="reason">
					<el-select v-model="refuseForm.reason" :placeholder="$t('seller.placeholder.refuseReason')">
						<el-option v-for="(item,index) in reasonOptions" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.refuseExplain')" prop="description">
					<el-input v-model="refuseForm.description" type="textarea" :rows="4" :placeholder="$t('seller.placeholder.refuseExplain')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.uploadVoucher')">
					<ul class="voucher-list">
						<li v-for="(item,index) in refuseForm.img_urls" :key="index">
							<img :src="item" alt="">
							<i class="el-icon-error" @click="removeThumb(index)"></i>
						</li>
						<li v-if="refuseForm.img_urls.length < 10">
							<el-upload
								:multiple="true"
								:show-file-list="false"
								:http-request="(data)=>{httpRequest(data.file)}"
								class="refuse-upload"
								action="#"
								v-loading="loading">
								<i class="el-icon-plus"></i>
								<p>{{$t('seller.actions.uploadVoucher')}}</p>
								<p>({{$t('seller.actions.uploadVoucherTips10')}})</p>
							</el-upload>
						</li>
					</ul>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleRefuse">{{$t('seller.actions.refuseApply')}}</el-button>
					<el-button @click="handleCloseRefuse">{{$t('seller.actions.cancel&Close')}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-scrollbar>
</template>

<script>
import { normalOSSUpload } from '@/utils/upload';
import { fetchAddressList } from '@/api/seller/logistics';
import { fetchRefundInfo, uploadRefund, fetchRefundReason } from "@/api/seller/order"

export default {
	filters: {
		statusFilter(status, option) {
			return option[status]
		}
	},
	computed: {
		orderStatus() {
			let status = 1
			if(this.refundInfo.refund_status !== 1 ) {
				if(this.refundInfo.goods_status ===3 ){
					status = 3
				} else {
					status = 2
				}
			} else {
				switch (this.refundInfo.consult_newest.step) {
					case 1:
						status = 1
						break;
					case 2:
						status = 2
						break;
					case 3:
						status = 3
						break;
					case 4:
						if(this.refundInfo.goods_status ===3 ){
							status = 3
						} else {
							status = 2
						}
						break;
					case 5:
						if(this.refundInfo.goods_status ===3 ){
							status = 3
						} else {
							status = 2
						}
						break;
				}
			}
			return status
		}
	},
	data() {
		return {
			refundInfo: null,
			orderInfo: {},
			// orderGoods: {},
			memberInfo: {},
			statusOption: this.$t('seller.options.refundSteps'),
			consultList: [],
			addressList: [],
			returnForm: {
				receiving_address_id: '',
				description: ''
			},
			returnRules: {
				receiving_address_id: [{ required: true, message: this.$t('seller.placeholder.refundsAddress'), trigger: 'change' }]
			},
			dialogVisibleReturn: false,
			reasonOptions: [],
			refuseForm: {
				reason: '',
				description: '',
				img_urls: []
			},
			refuseRules: {
				reason: [{ required: true, message: this.$t('seller.placeholder.refuseReason'), trigger: 'change' }],
				description: [{ required: true, message: this.$t('seller.placeholder.refuseExplain'), trigger: 'blur' }]
			},
			loading: false,
			dialogVisibleRefuse: false
		}
	},
	created() {
		this.getRefundInfo()
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		userLoadError(e) {
			e.target.src = this.$imageErrorUser;
			e.target.style.objectFit = 'fill';
		},
		systemLoadError(e) {
			e.target.src = this.$imageErrorAvatar;
			e.target.style.objectFit = 'fill';
		},
		getReasonOptions() {
			fetchRefundReason({ uuid: this.$store.getters.uuid }).then(response => {
				const { data } = response
				this.reasonOptions = data.refund_reject_reasons
			})
		},
		getRefundInfo() {
			const params = {
				apply_refund_id: this.$route.params.id
			}
			fetchRefundInfo(params).then(response => {
				const { data } = response
				this.refundInfo = data
				this.orderInfo = data.order_info
				this.memberInfo = data.member_info
				this.consultList = data.consult_list
				this.getReasonOptions()
				this.getAddressList()
			}).catch(() => {
				this.$router.push({ name: 'refundList' })
			})
		},
		getAddressList(){
			let param = {
				page_index: 0,
				page_size: 1000
			}
			fetchAddressList(param).then(response => {
				this.addressList = response.data.list
				this.addressList.forEach(item => {
					if(item.is_receiving_addr == '1') {
						this.returnForm.receiving_address_id = item.shipaddr_id
					}
				})
			})
		},
		getAddressStr(data) {
			const zipcode = data.zipcode || '000000'
			const phone = data.phone_mob || data.phone_tel
			const address = data.region_name + data.address + ', ' + zipcode + ', ' + data.shipaddr_name + ', ' + phone
			return address
		},
		handleAgree() {
			this.$confirm(this.$t('seller.confirm.agreeOrderRefund'),this.$t('seller.confirm.title'),{
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning',
			}).then(()=>{
				const params = {
					apply_refund_id: this.refundInfo.apply_refund_id,
					step_status: 1
				}
				uploadRefund(params).then(() => {
					this.$router.go(0)
				})
			}).catch(()=>{ })
		},
		handleAgreeReturn() {
			this.$refs.returnForm.validate((valid) => {
				if(valid) {
					this.$confirm(this.$t('seller.confirm.agreeRefundApply'),this.$t('seller.confirm.title'),{
						confirmButtonText: this.$t('seller.actions.confirm'),
						cancelButtonText: this.$t('seller.actions.cancel'),
						type: 'warning',
					}).then(()=>{
						const params = {
							...this.returnForm,
							apply_refund_id: this.refundInfo.apply_refund_id,
							step_status: 1
						}
						uploadRefund(params).then(() => {
							this.$router.go(0)
						})
					}).catch(()=>{
						this.dialogVisibleReturn = false
					})
				} else {
					return false
				}
			})
		},
		handleCloseReturn() {
			this.dialogVisibleReturn = false
		},
		async httpRequest(file){
			const isType = ['image/jpeg','image/png'].includes(file.type);
			const isSize = file.size / 1024 / 1024 < 3;
			if (!isType) {
				this.$message.error(this.$t('seller.upload.imageType'));
				return false;
			}
			if (!isSize) {
				this.$message.error(this.$t('seller.upload.imageSize3M'));
				return false;
			}
			this.loading = true;
			const { code, data } = await normalOSSUpload(file,'refund');
			if(code == 200){
				this.refuseForm.img_urls.push(data.url)
			}
			this.loading = false;
		},
		removeThumb(index) {
			this.refuseForm.img_urls.splice(index,1)
		},
		handleCloseRefuse() {
			this.dialogVisibleRefuse = false
		},
		handleRefuse() {
			this.$refs.refuseForm.validate((valid) => {
				if(valid) {
					this.$confirm(this.$t('seller.confirm.refuseRefundApply'),this.$t('seller.confirm.title'),{
						confirmButtonText: this.$t('seller.actions.confirmRefuse'),
						cancelButtonText: this.$t('seller.actions.cancel&Close'),
						type: 'warning',
					}).then(()=>{
						const params = {
							...this.refuseForm,
							apply_refund_id: this.refundInfo.apply_refund_id,
							step_status: 0
						}
						params.img_urls = params.img_urls.join(',') 
						uploadRefund(params).then(() => {
							this.$router.go(0)
						})
					}).catch(()=>{
						this.dialogVisibleRefuse = false
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.uu-box {
	::v-deep .el-scrollbar__view {
		padding: 20px;
		box-sizing: border-box;
	}
}

.page-breadcrumb {
	margin-bottom: 20px;

	::v-deep .el-breadcrumb {
		.el-breadcrumb__item {
			.el-breadcrumb__inner {
				color: #999999;

				&.is-link,
				a {
					font-weight: normal;
					color: #999999;

					&:hover {
						color: $--seller-primary;
					}
				}
			}

			&:last-child {
				.el-breadcrumb__inner {
					color: #333333;
				}
			}
		}
	}
}

.page-head {
	padding: 20px;
	background-color: #f7f8fa;
	border: 1px solid #f7f8fa;
	border-radius: 10px;

	.head-status {
		display: flex;
		justify-content: space-between;

		.status-box {
			margin: 5px 10px 0 0;

			h3 {
				font-weight: 500;
				font-size: 22px;
				color: #111;
				line-height: 32px;
			}

			p {
				color: #666666;
				font-size: 14px;
			}
		}

		.status-bar {
			width: 800px;

			::v-deep .el-steps--simple {
				padding-left: 0;
				padding-right: 0;

				.el-step.is-simple {
					.el-step__head {
						&.is-success {
							color: $--seller-primary;
							border-color: $--seller-primary;
						}
					}
					.el-step__main {
						.el-step__title {
							&.is-success {
								color: #999999;
							}
						}
					}
					&:not(:last-of-type) {
						.el-step__title {
							max-width: 62%;
						}
					}
				}
			}
		}
	}

	.head-process {
		font-size: 14px;
		line-height: 24px;
		color: #666666;
		margin-bottom: 10px;
	}

	.head-tips {
		border-top: 1px solid #e5e5e5;
		padding-top: 10px;
		line-height: 22px;
		color: #888888;
		margin-bottom: 10px;
		list-style: disc;
		padding-left: 20px;
	}

	.head-actions {
		li {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.head-bottom {
		color: #999999;
		padding-top: 20px;
		border-top: 1px solid #e5e5e5;
	}

	.dialog-address {
		::v-deep .el-scrollbar {
			height: 500px !important;

			.el-scrollbar__view {
				padding: 0;
			}
		}

		.dialog-address-main {
			padding: 0 40px;
			overflow: hidden;

			.item {
				display: flex;
				line-height: 32px;
				margin-bottom: 5px;
				font-size: 14px;

				.item-label {
					width: 100px;
					padding-right: 10px;
				}

				.item-block {
					flex: 1;
					width: 0;
					display: block;
				}

				.item-title {
					span {
						margin-right: 20px;
					}

					a {
						color: $--seller-primary;
						text-decoration: underline;
					}
				}
			}

			.address-line {
				padding: 4px 0;
				line-height: 32px;

				.line-label {
					min-width: 68px;
					display: inline-block;
					color: #999999;
				}
			}

			::v-deep .el-form {
				.el-form-item {
					margin-bottom: 10px;
				}
			}

			::v-deep .el-select {
				width: 100%;
			}
		}
	}
}

.page-main {
	margin-top: 20px;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #f7f8fa;
	background-color: #f7f8fa;

	.detail-top {
		margin-bottom: 15px;

		.title {
			font-size: 18px;
			padding: 12px 0;
			font-weight: 700;
			color: #111;
			position: relative;
			display: inline-block;
		}
	}

	.detail-layout {
		display: flex;
		justify-content: space-between;

		.item {
			flex: 1;

			.item-title {
				font-weight: 700;
				font-size: 14px;
				color: #111;
				line-height: 15px;
				margin-bottom: 15px;
			}

			.item-row {
				display: flex;
				line-height: 30px;
			}

			.item-label {
				flex-shrink: 0;
				font-size: 14px;
				color: #888888;
				margin-right: 5px;
			}

			.item-value {
				flex: 1;
				width: 0;
				color: #111;
				font-size: 14px;

				.copy {
					color: $--seller-primary;
					margin-left: 5px;
					cursor: pointer;
				}
			}
		}
	}

	.item-goods {
		display: flex;
		margin-bottom: 10px;

		.item-pic {
			width: 68px;
			height: 68px;
			flex-shrink: 0;
			overflow: hidden;
			border-radius: 12px;
			margin-right: 18px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			.name {
				color: #333333;
				font-size: 14px;
				line-height: 24px;
			}

			.text {
				color: #999999;
				line-height: 24px;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.history-title {
	font-size: 18px;
	line-height: 24px;
	margin-top: 20px;
}

.history-list {
	.item {
		display: flex;
		padding: 20px;
		border-bottom: 1px solid #f0f2f5;

		.pic {
			width: 68px;
			height: 68px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
			}
		}

		.item-main {
			flex: 1;
			width: 0;
			margin-left: 20px;

			.name {
				color: #333333;
				font-size: 14px;
				line-height: 20px;
			}

			.time {
				color: #999999;
				font-size: 12px;
				line-height: 18px;
			}

			.text {
				color: #333333;
				font-size: 12px;
				line-height: 18px;
				margin-top: 5px;
			}
		}
		&:last-child {
			border-bottom: none;
		}
	}
}
.voucher-list {
	display: flex;
	flex-flow: wrap;
	li {
		width: 100px;
		height: 100px;
		margin-right: 15px;
		margin-bottom: 15px;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.el-icon-error {
			top: -10px;
			right: -10px;
			font-size: 24px;
			cursor: pointer;
			position: absolute;
		}
		&:nth-child(5n) {
			margin-right: 0;
		}
	}
}
.refuse-upload {
	width: 100%;
	height: 100%;
	::v-deep .el-upload {
		width: 100%;
		height: 100%;
		padding: 16px 0;
		border: 1px solid #e5e5e5;
		box-sizing: border-box;
		color: #888888;
		display: flex;
		flex-flow: column;
		.el-icon-plus {
			font-size: 32px;
			margin-bottom: 4px;
		}
		p {
			font-size: 12px;
			line-height: 16px;
		}
	}
}
</style>